<template>
  <div class="row justify-content-between">
    <div class="col-12 col-xl">
      <div class="row">
        <div class="col-12 col-sm-auto offset-0 mb-4">
          <div class="row align-items-center no-gutters">
            <div class="col-auto">
              <p class="mb-0 mr-2">{{ $t('show')}}</p>
            </div>
            <div class="col-auto offset-0">
              <select
                name="per_page"
                class="form-control"
                v-model="filterFormdata.per_page"
              >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option v-if="isLargeList" value="1000">1000</option>
                <option v-if="isLargeList" value="2000">2000</option>
                <option v-if="isLargeList" value="5000">5000</option>
              </select>
            </div>
            <div class="col offset-0 ml-2">
              <p class="mb-0">{{ $t('entries')}}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-7 col-lg-5 offset-0 mb-4">
          <div class="row align-items-center no-gutters">
            <div class="col-auto">
              <p class="mb-0 mr-2">{{ $t('date')}}</p>
            </div>
            <div class="col offset-0">
              <v-date-picker
                name="date"
                v-model="filterFormdata.date"
                :locale="$i18n.locale"
                :model-config="modelConfig"
                :masks="{ input: $i18n.locale === 'en' ? 'MMMM DD, YYYY' : 'DD.MM.YYYY'}"
                class="inline-block h-full"
                is-range
              >
                <template v-slot="{ inputValue, togglePopover, inputEvents }">
                  <div class="form-control-clear">
                    <input
                      :value="inputValue.start === null ? '' : inputValue.start+' - '+inputValue.end"
                      class="form-control input"
                      v-on="inputEvents"
                      @focus="togglePopover()"
                      @change="togglePopover()"
                    />
                    <button class="clear" @click="$emit('clear-date')" v-if="inputValue.start !== null && inputValue.end !== null"></button>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-auto offset-0 mb-4">
          <div class="row align-items-center no-gutters">
            <div class="col-auto">
              <p class="mb-0 mr-2">{{ $t('sortBy')}}</p>
            </div>
            <div class="col-auto offset-0">
              <select
                name="sort"
                v-model="filterFormdata.sort"
                class="form-control"
              >
                <option value="desc">{{ $t('latestFirst') }}</option>
                <option value="asc">{{ $t('oldestFirst') }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto col-xl-2 offset-0 mb-4" v-if="isCsv">
      <div class="row align-items-center no-gutters">
        <div class="col-auto">
          <button type="button" @click="$emit('download-csv')" class="text-capitalize btn btn-outline-secondary">
            <i class="feather icon-download mx-1"></i>
            {{ $t('exportCsv') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="col-auto col-xl-auto offset-0 mb-4" v-if="isSearch">
      <div class="row align-items-center no-gutters">
        <div class="col-auto">
          <p class="mb-0 mr-2">{{ $t('search') }}</p>
        </div>
        <div class="col">
          <input
            name="search"
            v-model="filterFormdata.search"
            class="form-control"
            :placeholder="$t('searchInputTest')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, toRef, ref } from 'vue'

export default {
  name: 'PaginatedTableFilter',
  emits: ['update', 'clear-date', 'download-csv'],
  props: {
    formdata: {
      type: Object,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: () => true,
    },
    isCsv: {
      type: Boolean.apply,
      default: () => false,
    },
    csvHandler: {
      type: Function,
    },
    isLargeList: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const filterFormdata = toRef(props, 'formdata')
    const timer = ref(null)
    const timeout = 200
    const modelConfig = {
      type: 'string',
      mask: 'YYYY-MM-DD',
    }
    watch(filterFormdata.value, () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        emit('update', filterFormdata.value)
      }, timeout)
    })
    return {
      filterFormdata,
      modelConfig,
    }
  },
}
</script>
